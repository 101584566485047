
html, body {
    height: 100%;
    margin: 0;
  }
  
  .rootlayout {
    height: 100%;
  }
  
  
  motion.div {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    will-change: opacity, background-color; /* Optimize for transitions */
  }
  