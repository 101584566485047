@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&family=Noto+Sans+JP:wght@100..900&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

html {
  font-family: 'Noto Sans JP', sans-serif;
  width:100%;
  background: #F9FAFB;
  /* background-image: url('./resources/45-degree-fabric-light.png'); */
  height:100%;
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

body {
  width:100%;
  height:100%;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @media (max-width:850px) {
  #root > div {
    padding-left: 8%;
    padding-right: 8%;
  }
} */