/* The switch - the box around the slider */
.switch {
  position: relative;
  display: flex;
  width: 110px;
  height: 40px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 360px;
  border: 3px solid var(--Neutral-200, #E5E7EB);
  background: var(--Generic-White, #FFF);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  display:flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .4s;
  border-radius: 34px;
}

.highlight {
  position:absolute;
  z-index: 0;
  left:9.5px;
  background: var(--Neutral-500, #1F2937);
  width: 30px;
  height: 26px;
  border-radius: 360px;
}

.en {
  position:absolute;
  bottom: 6px;
  z-index:1;
  color:  #ffffff;
  display: block;
  margin:auto;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  width: 30px;
  padding: 2px;
  border-radius: 360px;
}

.jp {
  position:absolute;
  z-index:1;
  bottom: 6px;
  left: 50px;
  display:block;
  color: var(--Neutral-500, #6B7280);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  width: 46px;
  padding: 2px;
  border-radius: 360px;
}

input:checked + .slider > .en {
  transition: 0.5s;
  background: none;
  color: var(--Neutral-500, #6B7280);
}

input:checked + .slider > .jp {
  transition: 0.5s;
  color: var(--Neutral-500, #ffffff);
}

input:checked + .slider > .highlight {
  transition: 0.5s;
  left: 46px;
  width: 54px;
  color: var(--Neutral-500, #6B7280);
}

input:not(:checked) + .slider > .highlight {
  transition: 0.5s;
  left: 9.5px;
  width: 30px;
}

input:not(:checked) + .slider > .en {
  transition: 0.5s;
  color: var(--Neutral-500, #ffffff);
}