footer {
    display: flex;
    padding: 100px 8% 100px 8%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    margin:auto;
    max-width:1440px;
    
}

.socials {
    display: flex;
    align-items: center;
    gap: 26px;
}

.socials a:hover {
    filter: brightness(80%);
}

.media-icon {
    width:36px;
    height:36px;
}

.media-icon img {
    width: 100%;
    height: 100%;
}

footer > h3 {
    color: var(--Neutral-500, #6B7280);
    font-size: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
}

@media (max-width: 600px) {

    footer {
        padding: 50px 8% 25px 8%;
        gap: 16px;
    }

    .socials {
        gap: 12px;
    }

    .media-icon {
        width: 20px;
        height: 20px;
    }

    footer > h3 {
        font-size: 14px;
    }
}