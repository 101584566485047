.about-layout {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap:80px;
    margin:auto;
    max-width: 1440px;
    padding: 0px 8% 0px 8%;
    opacity: 0; /* Start with container hidden */
    animation: fadeInTopToBottom 1s ease-out forwards;
}

.about-layout h3 {
    color: var(--Neutral-800, #1F2937);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

.text-frame h3 {
    color: var(--Neutral-800, #333f50);
    font-family: 'Manrope';
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.profile-frame1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 90px;
    margin-top:150px;
    position: relative;
    overflow: hidden;
   
}

@keyframes fadeInTopToBottom {
    0% {
      opacity: 0;
      transform: translateY(-50px); /* Start slightly above the final position */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Final position */
    }
  }
.profile-frame2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 90px;
}


.text-container p {
    color: var(--Neutral-500, #6B7280);
    font-family: "Noto Sans JP";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* .skillset {
    margin-top:100px;
} */



.skill-logo {
    margin-top: 24px;
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    max-width:400px;
}

.skill-logo img {
    width: 56px;
    height: 56px;
    margin-right: 40px;
}

.bullet {
    margin-top: 20px;
}

/* .values {
    margin-top:100px;
} */

.profile-image {
    width: 800px;
    max-height: 100%;
    position:relative;
    height:500px;
}

.profile-image > img {
    width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: fade 25s infinite;
}
.profile-pic:nth-child(1) {
    animation-delay: 0s;
  }
  
  .profile-pic:nth-child(2) {
    animation-delay: 5s; /* Adjust based on the total duration divided by number of images */
  }
  
  .profile-pic:nth-child(3) {
    animation-delay: 10s;
  }
  
  .profile-pic:nth-child(4) {
    animation-delay: 15s;
  }
  
  .profile-pic:nth-child(5) {
    animation-delay: 20s;
  }
  
  @keyframes fade {
    0% {
      opacity: 0;
    }
    8% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    28% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

.text-frame {
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}



.text-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}


.profile-frame2 > h4 {
    color: var(--Neutral-800, #333f50);
    font-family: 'Manrope';
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}


@media (max-width: 1200px) {

    .profile-frame1 {
        flex-direction:column;
    }
}


@media (max-width: 600px) {

    .about-layout {
        gap: 40px;
    }

    .profile-frame1 {
        flex-direction:column;
        margin-top: 75px;
        gap: 45px;
    }

    .about-layout h3 {
        font-size: 16px;
    }

    .text-container p {
        font-size: 12px;
    }

    .text-container {
        margin-top: 12px;
        gap: 12px;
    }

    .profile-frame2 > h4 {
        font-size: 12px;
    }

    .skill-logo {
        margin-top:12px;
    }

    .skill-logo img {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }

    .bullet {
        margin-top: 10px;
    }

    .skillset > h3 {
        margin-bottom: 12px;
    }
}