.lead-banner {
    width: 100%;
    margin: 100px 0px 100px 0px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
}

.lead-banner > img {
    width: 100%;
    height: auto;   /* Maintain aspect ratio */
}

.banner {
    width: 100%;
    margin: 0px 0px 100px 0px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
}

.span-banner {
    width: 100%;
    margin: -50px 0px 200px 0px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
}

.span-banner > img {
    width: 100%;
    height: auto;   /* Maintain aspect ratio */
}


.banner > img {
    width: 100%;
    height: auto;   /* Maintain aspect ratio */
}

.approach-bg > img {
    width: 100%;
    height: auto;   /* Maintain aspect ratio */
    max-width: 1440px;
    padding: 0px 8% 0px 8%;
}

.case-study {
    max-width: 1440px;
    padding: 0px 8% 0px 8%;
    margin:auto;
    display:flex;
    flex-direction: column;
    opacity: 0;
    animation: caseIn 2s forwards;
}
@keyframes caseIn {
    to {
      opacity: 1;
    }
  }
  

.overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:50px;
    gap:20px;
}

.overview h5 {
    color: var(--Neutral-700, #404040);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.parent {
    margin-bottom: 150px;
}

.child {
    margin-bottom: 100px;
}

.baby {
    margin-bottom: 50px;
}

.child:last-child {
    margin-bottom:0px;
}
.case-p {
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.case-p > h2 {
    color: var(--Neutral-800, #1F2937);
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 20px;
}

.case-p > h3 {
    color: var(--Neutral-800, #861336);
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.case-p > h4 {
    color: var(--Neutral-800, #78350F);
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom:20px;
}

.case-p > p {
    color: var(--Neutral-700, #404040);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.img-container > img {
    width: 100%;
    height: auto;   
}

.width-800 {
    max-width: 800px;
}

.width-1200 {
    max-width:1200px;
    margin: 50px 0 50px 0;
}

.img-container {
    display:flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
    flex-direction: column;
}

.gif-container {
    display:flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
    flex-direction: row;
    gap: 5%;
    margin: 50px 0 50px 0;
}

.mobile-container {
    max-width:375px;
    height:auto;
}
.mobile-gif {
    width:100%;
}

.img-container > h6 {
    color: var(--Neutral-700, #404040);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 800px;
    text-align: center;
    margin-bottom:70px;
}

.emphasis {
    color: var(--Neutral-700, #717171);
    font-size: 36px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: justify;
}

.case-p li {
    color: var(--Neutral-700, #404040);
    margin-left: 2%;
    font-size:16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.space {
    width: auto;
    height: 24px;
}

@media (max-width: 600px) {

    .lead-banner {
        margin: 50px 0px 50px 0px;
    }

    .banner {
        margin: 0px 0px 50px 0px;
    }

    .span-banner {
        margin: -50px 0px 100px 0px;
    }
    .parent {
        margin-bottom: 75px;
    }
    .child {
        margin-bottom: 50px;
    }

    .baby {
        margin-bottom: 25px;
    }

    .case-p {
        gap:10px;
    }
    .overview {
        margin-bottom: 25px;
    }
    .overview h5 {
        font-size: 12px;

    }
    .case-p > h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .case-p > h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .case-p > h4 {
        font-size: 16px;
        margin-bottom:10px;
    }
    .case-p > p {
        font-size: 12px;
    }
    .img-container > h6 {
        font-size: 10px;
        margin-bottom: 35px;
    }
    .emphasis {
        font-size: 20px;
    }
    .case-p li {
        font-size:12px;
    }

    .gif-container {
        margin: 25px 0 25px 0;
    }
    
    .space {
        height:12px;
    }

    .width-1200 {
        margin: 25px 0 25px 0;
    }
}