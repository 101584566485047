.work-layout {
  margin:auto;
  max-width:1440px;
  padding:0px 8% 0px 8%;
  animation: fadeInTopToBottom 1s ease-out forwards;
}

.display {
    display: flex;
    width: 100%;
    height: 572px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-shrink: 0;
}

.display-left {
    display: flex;
    width: 620px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.display-right {
    position:relative;
    display: flex;
    flex: 1 0 0;
    align-self: stretch;
}

.main-display-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-display-text > h1 {
    color: var(--Neutral-800, #1F2937);
    font-family: 'Manrope';
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4px;
}

.main-display-text > h2 {
    color: var(--Neutral-800, #1F2937);
    font-family: "Noto Sans JP";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.4px;
}

.sub-display-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.sub-display-text > h3 {
    color: var(--Neutral-600, #4B5563);
    font-family: "Noto Sans JP";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
}

.download {
    text-decoration: none;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-300, #D1D5DB);
    background: var(--Generic-White, #FFF);
    color: var(--Neutral-700, var(--Neutral-700, #374151));
    text-align: center;
    /* Label/Medium/Medium */
    font-family: 'Inter';
    font-size: var(--font-size-x-sm, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
}

.download:hover {
    background: var(--Generic-White, #F3F4F6);
}

/* Fly in animation*/
@keyframes flyIn {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.main-display-text h1,
.main-display-text h2,
.sub-display-text h3,
.sub-display-text .download {
  opacity: 0; /* Start elements as invisible */
  animation: flyIn 0.5s ease-out forwards; /* Duration reduced to 0.5s */
}

.main-display-text h1 {
  animation-delay: 0.25s; /* Delay reduced to 0.25s */
}

.main-display-text h2 {
  animation-delay: 0.5s; /* Delay reduced to 0.5s */
}

.sub-display-text h3 {
  animation-delay: 0.75s; /* Delay reduced to 0.75s */
}

.sub-display-text .download {
  animation-delay: 0.75s; /* Delay reduced to 1s */
}






/* Combined fadeIn and float animations */
.cyan {
  width: 200px;
  height: 200px;
  position: absolute;
  right: 200px;
  top: 132px;
  background: #A5F3FC;
  filter: blur(25px) brightness(1.2);
  border-radius: 100%;
  mix-blend-mode: multiply;
  animation: fadeIn 1s ease-out forwards, floatcyan 50s infinite linear;
  background-blend-mode: overlay;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1), 
              0 0 30px 15px rgba(0, 0, 0, 0.05), 
              0 0 45px 25px rgba(0, 0, 0, 0.02);
  opacity: 0; /* Start as invisible */
  animation-delay: 0.5s, 0s; /* Delay fadeIn by 0.5s, floatcyan starts immediately */
}

.purple {
  width: 200px;
  height: 200px;
  position: absolute;
  right: 48px;
  top: 141px;
  background: #FDE68A;
  filter: blur(25px) brightness(1.2);
  border-radius: 100%;
  mix-blend-mode: multiply;
  animation: fadeIn 1s ease-out forwards, floatyellow 50s infinite linear;
  background-blend-mode: overlay;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1), 
              0 0 30px 15px rgba(0, 0, 0, 0.05), 
              0 0 45px 25px rgba(0, 0, 0, 0.02);
  opacity: 0; /* Start as invisible */
  animation-delay: 1s, 0s; /* Delay fadeIn by 1s, floatyellow starts immediately */
}

.magenta {
  width: 200px;
  height: 200px;
  position: absolute;
  right: 130px;
  bottom: 125px;
  background: #F9A8D4;
  filter: blur(25px) brightness(1.2);
  border-radius: 100%;
  mix-blend-mode: multiply;
  animation: fadeIn 1s ease-out forwards, floatmagenta 50s infinite linear;
  background-blend-mode: overlay;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1), 
              0 0 30px 15px rgba(0, 0, 0, 0.05), 
              0 0 45px 25px rgba(0, 0, 0, 0.02);
  opacity: 0; /* Start as invisible */
  animation-delay: 1.5s, 0s; /* Delay fadeIn by 1.5s, floatmagenta starts immediately */
}

/* Keyframes for fadeIn effect */
@keyframes fadeIn {
0% {
  opacity: 0; /* Optional: slight slide from below */
}
100% {
  opacity: 1; /* Move to original position */
}
}

/* Keyframes for floating animations */
@keyframes floatcyan {
  0% { transform: translate(0px, 0px) scale(1); }
  25% { transform: translate(150px, 0px) scale(1.1); }
  50% { transform: translate(0px, 150px) scale(0.9); }
  75% { transform: translate(100px, 0px) scale(1); }
  100% { transform: translate(0px, 0px) scale(1); }
}

@keyframes floatyellow {
  0% { transform: translate(0px, 0px) scale(1); }
  25% { transform: translate(-200px, 0px) scale(0.9); }
  50% { transform: translate(0px, -50px) scale(1.1); }
  75% { transform: translate(-100px, 0px) scale(0.9); }
  100% { transform: translate(0px, 0px) scale(1); }
}

@keyframes floatmagenta {
  0% { transform: translate(0px, 0px) scale(1); }
  25% { transform: translate(50px, -150px) scale(1.1); }
  50% { transform: translate(0px, 0px) scale(1); }
  75% { transform: translate(-50px, -100px) scale(1); }
  100% { transform: translate(0px, 0px) scale(1); }
}

  .line-divider {
    width: 100%;
    height: 1.5px;
    background: #E5E7EB;
  }

  .title-container {
    display: flex;
    padding: 100px 0px 0px 0px;
    align-items: center;
    align-self: stretch;
    margin-bottom: 50px;
  }

  .title-container > h2 {
    color: var(--Neutral-800, #1F2937);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    
  }

  .project-list {
    display: flex;
    
    max-width: 1216px;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 64px;
    margin:auto;
    margin-bottom: 100px;
  }
  
  .project-row {
    display:flex;
    flex: auto;
    flex-direction: row;
    gap: 32px;
  }

  .project-link {
    display:flex;
    text-decoration:none;
    flex: auto;
  }

  .project-container {
    display: flex;
    max-width: 592px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
 /*    border: 1px solid var(--Neutral-300, #D1D5DB); */
    background: #ffffff;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.05), 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    }
  


  .project-image {
    display: flex;
    height: 450px;
    padding: 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

  .hover-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  .hover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .project-link:hover .hover-video {
    display: block; /* Show the video on hover */
  }

  .project-text {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .title-row > h4 {
    color: var(--Neutral-800, #1F2937);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
  }

  .title-row > h5 {
    color: var(--Neutral-500, #6B7280);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
  }

  .project-desc {
    color: var(--Neutral-500, #6B7280);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 1100px) {
    .projects {
      margin: auto;
    }
    .project-list {
      gap: 100px;
    }
    .project-row {
      flex-direction: column;
      gap: 100px;
    }
  
  }

  @media (max-width: 850px) {

      .display {
        gap: 0px;
      }

      .display-left {
        width:100%;
      }
      .cyan, .magenta, .purple {
        mix-blend-mode: darken;
      }
  }


  @media (max-width: 600px) {

    .display {
      height: 372px;
    }
    .display-left {
      gap:20px;
    }

    .main-display-text > h1 {
      font-size: 28px;
    }

    .main-display-text > h2 {
      font-size: 18px;
    }

    .sub-display-text {
      gap:10px;
    }

    .sub-display-text > h3 {
      font-size: 12px;
    }
    
    .download {
      font-size:10px;
      padding: 6px 12px;
    }

    .download-icon {
      width:16px;
      height:16px;
    }
    .cyan, .magenta, .purple {
      width: 100px;
      height:100px;
    }

    .title-container {
        padding: 50px 0 0 0;
        margin-bottom: 25px;
    }

    .title-container > h2 {
      font-size:16px;
    }

    .project-list {
      padding: 32px 0px;
      gap: 50px;
      margin-bottom: 30px;
    }

    .project-row {
      gap: 50px;
    }

    .project-image {
      height:300px;
      padding: 16px;
    }

    .project-text {
      padding: 16px;
      gap: 8px;
    }

    .title-row > h4 {
      font-size: 14px;
    }

    .title-row > h5 {
      font-size: 10px;
    }

    .project-desc {
      font-size: 12px;
    }
    @keyframes floatcyan {
      0% { transform: translate(0px, 0px) scale(1); }
      25% { transform: translate(75px, 0px) scale(1.05); }
      50% { transform: translate(0px, 75px) scale(0.95); }
      75% { transform: translate(50px, 0px) scale(1); }
      100% { transform: translate(0px, 0px) scale(1); }
  }
  
  @keyframes floatyellow {
      0% { transform: translate(0px, 0px) scale(1); }
      25% { transform: translate(-100px, 0px) scale(0.95); }
      50% { transform: translate(0px, -25px) scale(1.05); }
      75% { transform: translate(-50px, 0px) scale(0.95); }
      100% { transform: translate(0px, 0px) scale(1); }
  }
  
  @keyframes floatmagenta {
      0% { transform: translate(0px, 0px) scale(1); }
      25% { transform: translate(25px, -75px) scale(1.05); }
      50% { transform: translate(0px, 0px) scale(1); }
      75% { transform: translate(-25px, -50px) scale(1); }
      100% { transform: translate(0px, 0px) scale(1); }
  }
  
  }