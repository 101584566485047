


.lead-banner > img {
    width: 100%;
    height: auto;   /* Maintain aspect ratio */
}


.step-title {
    color:  #404040;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.5px;
}

.section-title {
    color: #1F2937;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.esports-case {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fade-in {
    transform: translateY(100px);
    opacity: 0;
    animation: fadeIn 1.5s forwards; /* 2s duration for the fade-in effect */
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 600px) {

    .step-title {
        font-size: 12px;
    }

    .section-title {
        font-size: 16px;
    }
  }
  