header {
    max-width:1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:auto;
    margin-top: 60px;
    padding:0px 8% 0px 8%;
}

nav {
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.links {
    display: flex;
    height: 40px;
    align-items: center;
}

.link {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.svg-hover:hover path {
    stroke: #111827; /* Change this to your desired color */
    stroke-width: 3.5;
  }

header nav a.active {
    color: #111827;
    font-weight: 900;
}

.link a{
    text-decoration: none;
    color: var(--Neutral-500, #6B7280);
    text-align: center;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
}

.link a:hover {
    color: #374151;
}

.hamburger {
    display:none;
    width:32px;
    height:32px;
}

.hamburger img {
    width: 100%;
    height:100%;
    object-fit: fill;
}



@media (max-width: 850px) {
    
    header {
        margin-top:30px;
    }
    .hamburger {
        display:block;
    }
    .links {
      flex-direction: column;
      background: white;
      position:absolute;
      height:0;
      overflow: hidden;
      top: 60px;
      right:0px;
      border-radius: 8px;
      z-index:5;
      box-shadow: 2px 2px 5px rgba(108, 108, 108, 0.5);
    }
    .links.open {
        display: flex;
        height:fit-content;
    }
}