/* Slider.css */

.scroll-container {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: grab;
    margin-bottom: 100px;
  }


  .scroll-container:active {
    cursor: grabbing;
  }
  .scrolling-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap; /* Prevent line breaks */
    gap: 60px;
    pointer-events: none;
  }

  .scrolling-box-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap; /* Prevent line breaks */
    gap: 160px;
    pointer-events: none;
  }

  .scrolling-image {
    display: inline-block;
    max-width: 5400px;
    pointer-events: none;
  }
  
  .scrolling-image-mobile {
    display: inline-block;
    max-width: 3400px;
    pointer-events: none;
  }
  .scroll-container, .scrolling-box, .scrolling-image {
    user-select: none; /* Prevent text/image selection */
    -webkit-user-drag: none; /* Disable dragging on WebKit-based browsers */
    -webkit-touch-callout: none; /* Disable callouts on touch devices */
}

/* Prevent the default drag behavior on images */
.scrolling-image {
    pointer-events: none; /* Disable mouse events on the image */
}


  @media (max-width: 600px) {

    .scroll-container {
        margin-bottom: 50px;
    }
    .scrolling-image {
        display: inline-block;
        max-width: 2200px;
        
      }
    .scrolling-box {
        gap: 30px;
    }

    .scrolling-image-mobile {
        max-width: 1700px;

    }

    .scrolling-box-mobile {
        gap: 80px;
    }
  }