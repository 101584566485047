.progress-bar-container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99; /* Ensure it's above other content */
  pointer-events: none; /* Prevent the bar from capturing any pointer events */
  }
  
  .progress-bar {
    height: 8px;
    border-radius: 0px 16px 16px 0px;
  }
  